<template>
  <VForm @submit.prevent="onSubmit">
    <template #default="form">
      <CytomineModalCard
        :title="$t(term ? 'update-term' : 'create-term')"
        class="term-modal"
      >
        <IdxInput
          v-model="name"
          :label="$t('name')"
          name="name"
          required
          class="mb-3"
          maxlength="75"
        />

        <sketch-picker v-model="color" :preset-colors="presetColors" />

        <IdxInput
          v-model="pointSize"
          :label="$t('point-size')"
          name="pointSize"
          placeholder="optional"
          class="my-3"
          type="number"
          min="1"
        />

        <template #footer>
          <IdxBtn @click="$parent.close">
            {{ $t('cancel') }}
          </IdxBtn>
          <IdxBtn type="submit" :disabled="!form.valid" color="primary">
            {{ $t('save') }}
          </IdxBtn>
        </template>
      </CytomineModalCard>
    </template>
  </VForm>
</template>

<script>
import { Sketch } from 'vue-color';
import noteApi from '../../services/noteApi';
import CytomineModalCard from '@/components/utils/CytomineModalCard';

export default {
  name: 'TermModal',
  components: {
    'sketch-picker': Sketch,
    CytomineModalCard,
  },
  props: {
    term: { type: Object, default: () => null },
    ontology: { type: Object, required: true },
  },
  data() {
    return {
      name: '',
      pointSize: null,
      color: null,
    };
  },
  computed: {
    presetColors() {
      return [
        '#F44E3B',
        '#FB9E00',
        '#FCDC00',
        '#68BC00',
        '#16A5A5',
        '#009CE0',
        '#7B10D8',
        '#F06292',
        '#000',
        '#777',
        '#FFF',
      ];
    },
  },
  created() {
    this.name = this.term ? this.term.name : '';
    this.pointSize = this.term ? this.term.pointSize : null;
    this.color = { hex: this.term ? this.term.color : this.randomColor() };
  },
  methods: {
    randomColor() {
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    },
    async onSubmit({ target }) {
      const data = {
        name: target.name.value,
        pointSize: parseInt(target.pointSize.value) || null,
        color: this.color.hex,
        ontologyId: this.ontology.id,
      };
      if (this.term) {
        await this.update(data);
      } else {
        await this.create(data);
      }
      this.$parent.close();
    },
    /**
     * @param {{ name: string, color:string, ontologyId: number, pointSize: number }} data
     * @returns {Promise<void>}
     */
    async create(data) {
      try {
        const term = await noteApi.post('/api/term.json', {
          json: {
            name: data.name,
            ontology: data.ontologyId,
            pointSize: data.pointSize,
            color: data.color,
          },
        });

        this.$notify({
          type: 'success',
          text: this.$t('term-creation-success'),
        });
        this.$emit('newTerm', term);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('term-creation-error'),
        });
      }
    },
    /**
     * @param {{ name: string, color:string, ontologyId: number, pointSize: number }} data
     * @returns {Promise<void>}
     */
    async update(data) {
      const { term } = this;
      term.name = data.name;
      term.color = data.color;
      try {
        await noteApi.put(`/api/term/${term.id}.json`, {
          json: {
            id: term.id,
            name: term.name,
            ontology: term.ontologyId,
            pointSize: data.pointSize,
            color: term.color,
            hidden: false,
          },
        });
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-term-update'),
        });
        this.$emit('updateTerm', term);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('term-update-error'),
        });
      }
    },
  },
};
</script>

<style>
.term-modal .vc-sketch {
  width: auto;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.term-modal .vc-sketch-saturation-wrap {
  padding-bottom: 8rem;
}

/* hide alpha channel */
.term-modal .vc-sketch-field--single:last-child {
  display: none;
}

.term-modal .vc-sketch-sliders {
  display: flex;
  align-items: center;
}

.term-modal .vc-sketch-hue-wrap {
  flex-grow: 1;
}

.term-modal .vc-sketch-alpha-wrap {
  display: none;
}
</style>
